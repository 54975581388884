import axios from 'axios';
const contentful = require('contentful-management')

// const client = contentful.createClient({
//      accessToken: process.env.CONTENTFUL_MANAGEMENT_TOKEN
// })

export const getArticleOrCategorySlugs = (category) => {
     if (typeof category == 'undefined') { return [] };
     let slugs = [category.slug];
     if (category.parentCategory != null) {
          let parentSlugs = getArticleOrCategorySlugs(category.parentCategory);
          if (Array.isArray(parentSlugs)) {
               slugs = slugs.concat(parentSlugs);
          }
     }
     return slugs;
}

export const getPreviewArticleOrCategorySlugs = (category) => {
     if (typeof category == 'undefined') { return [] };
     let slugs = [category.fields.slug];
     if (category.fields.parentCategory != null) {
          let parentSlugs = getPreviewArticleOrCategorySlugs(category.fields.parentCategory);
          if (Array.isArray(parentSlugs)) {
               slugs = slugs.concat(parentSlugs);
          }
     }
     return slugs;
}

export const getArticleOrCategoryBreadcrumbs = (category) => {
     if (typeof category == 'undefined') { return [] };
     let breadcrumbSlug = [{
          breadcrumb: category.breadcrumbValue,
          slug: "/healthzone/" + getArticleOrCategorySlugs(category).reverse().join('/')
     }]
     if (category.parentCategory != null) {
          let parentBreadcrumbs = getArticleOrCategoryBreadcrumbs(category.parentCategory);
          if (Array.isArray(parentBreadcrumbs)) {
               breadcrumbSlug = breadcrumbSlug.concat(parentBreadcrumbs);
          }
     }
     return breadcrumbSlug;
}

export const getArticleOrCategoryPreview = (category) => {
     if (typeof category.fields == 'undefined') { return [] };
     let breadcrumbSlug = [{
          breadcrumb: category.fields.breadcrumbValue,
          slug: "/healthzone/" + getPreviewArticleOrCategorySlugs(category).reverse().join('/')
     }]
     if (category.fields.parentCategory != null) {
          let parentBreadcrumbs = getArticleOrCategoryPreview(category.fields.parentCategory);
          if (Array.isArray(parentBreadcrumbs)) {
               breadcrumbSlug = breadcrumbSlug.concat(parentBreadcrumbs);
          }
     }
     return breadcrumbSlug;
}

export const getUrlParam = (href) => {
     var vars = {};
     const getParam = (m, key, value) => {
          vars[key] = value;
     };
     var parts = href.replace(/[?&]+([^=&]+)=([^&]*)/gi, getParam);

     return vars;
}

export const getLowerCaseUrlParam = (href) => {
     var vars = {};
     const getParam = (m, key, value) => {
          vars[key.toLowerCase()] = value;
     };
     var parts = href.replace(/[?&]+([^=&]+)=([^&]*)/gi, getParam);

     return vars;
}

export const validateEmail = (email) => {
     var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
}

export const setOmCode = (om, href) => {
     var omParams = getUrlParam(href)['OM'];
     if (omParams != null) {
          localStorage.setItem('Petinsurance_OM', omParams);
     } else if (om != null) {
          localStorage.setItem('Petinsurance_OM', om);
     }
}

export const setPhoneLocalStorage = (phone, href) => {
     var phoneParams = getUrlParam(href)['ph'];
     if (phoneParams != null) {
          localStorage.setItem('Petinsurance_TrackingPhone', phoneParams);
     } else if (phone != null) {
          localStorage.setItem('Petinsurance_TrackingPhone', phone);
     }
}

export const setCookieTestUser = (href) => {
     var testUserParams = getUrlParam(href)['testUser'];
     if ((typeof testUserParams != 'undefined' && testUserParams == 'true') || testUserParams == 'yes') {
          setCookie('testUser', 'Yes', 7);
     }
}

export const setCookie = (cname, cvalue, exdays) => {
     var d = new Date();
     d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
     var expires = "expires=" + d.toUTCString();
     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookieTestUser = (cname) => {
     var name = cname + "=";
     var decodedCookie = decodeURIComponent(document.cookie);
     var ca = decodedCookie.split(';');
     for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
               c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
               return c.substring(name.length, c.length);
          }
     }
     return "";
}
export const gtmTrackingDetailDataLayer = (_event, _data, _notInit) => {
     console.log({
          'event': _event,
          'prePageviewDetails': _data
     });
     window.dataLayer = window.dataLayer || [];
     window.dataLayer.push({
          'event': _event,
          'prePageviewDetails': _data
     });

     if (typeof _notInit == 'undefined') {
          initGtagTrackingCode();
     }
}

export const initGtagTrackingCode = () => {
     (function (w, d, s, l, i) {
          w[l] = w[l] || []; w[l].push({
               'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
          }); var f = d.getElementsByTagName(s)[0],
               j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
     })(window, document, 'script', 'dataLayer', 'GTM-WJXFWM');
}


export const addSlashToStr = (str) => {
     let newStr = '';

     if (typeof str != 'undefined' && str != null) {
          // Check if the last character is '/'
          newStr = (str.charAt(str.length - 1) == '/') ? str : (str + '/');
     }

     return newStr;
}

export const sendEmail = (data, fromEmail, toEmail) => {
     let rows = '';

     Object.entries(data).forEach(([key, value]) => {
          rows += `
                         <tr bgcolor="#EAF2FA">
                              <td colspan="2">
                                   <font style="font-family: sans-serif; font-size: 12px"><strong>${key}</strong></font>
                              </td>
                         </tr>
                         <tr bgcolor="#FFFFFF">
                              <td width="20">&nbsp;</td>
                              <td>
                                   <font style="font-family: sans-serif; font-size: 12px">${value}</font>
                              </td>
                         </tr>
                    `
     })

     const emailTemplate = `
          <table width="99%" border="0" cellpadding="1" cellspacing="0" bgcolor="#EAEAEA">
               <tbody>
                    <tr>
                         <td>
                              <table width="100%" border="0" cellpadding="5" cellspacing="0" bgcolor="#FFFFFF">
                                   <tbody>
                                       ${rows}
                                   </tbody>
                              </table>
                         </td>
                    </tr>
               </tbody>
          </table>
     `

     axios({
          method: 'post',
          url: `https://api.mailgun.net/v3/${process.env.MAILGUN_DOMAIN}/messages`,
          auth: {
               username: 'api',
               password: process.env.MAINGUN_API_KEY
          },
          params: {
               from: fromEmail,
               to: toEmail,
               subject: 'Quote',
               html: emailTemplate
          }
     }).then(
          response => {
               // console.log(response)
          },
          reject => {
               // console.log(reject)
          }
     )
}

export const updateRobots = () => {
     // client.getSpace(process.env.CONTENTFUL_SPACE_ID)
     //      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
     //      .then((environment) => environment.getEntries({ 'content_type': 'pgArticle', limit: 500 }))
     //      .then((response) => {
     //           response.items.map((item, index) => {
     //                // if (item.fields.robotsDirective) {
     //                //      if (item.fields.robotsDirective['en-US']) {
     //                //           item.fields.robotsDirective['en-US'] = '';
     //                //           item.update();
     //                //      }
     //                // }

     //                // if (item.isUpdated()) {
     //                //      item.publish();
     //                // }
     //           })
     //      })
     //      .catch(console.error)
}

export const importEmployerGroups = (eGroupsData) => {
     eGroupsData.map(({ node: eGroup }, index) => {
          // client.getSpace(process.env.CONTENTFUL_SPACE_ID)
          //      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
          //      .then((environment) => environment.createEntry('employerGroups', {
          //           fields: {
          //                contentName: {
          //                     'en-US': eGroup.contentName
          //                },
          //                groupType: {
          //                     'en-US': eGroup.groupType
          //                },
          //                groupDisplayName: {
          //                     'en-US': eGroup.groupDisplayName
          //                },
          //                welcomeHeader: {
          //                     'en-US': eGroup.welcomeHeader
          //                },
          //                slug: {
          //                     'en-US': eGroup.slug
          //                },
          //                vanityUrl: {
          //                     'en-US': eGroup.vanityUrl
          //                },
          //                // groupLogo: {
          //                //      'en-US': ''
          //                // },
          //                importantGroupNotice: {
          //                     'en-US': eGroup.importantGroupNotice ?
          //                          {
          //                               nodeType: 'document',
          //                               data: {},
          //                               content: [
          //                                    {
          //                                         nodeType: 'paragraph',
          //                                         data: {},
          //                                         content: [
          //                                              {
          //                                                   nodeType: 'text',
          //                                                   value: eGroup.importantGroupNotice,
          //                                                   data: {},
          //                                                   marks: [{ type: 'normalText' }]
          //                                              },
          //                                              // {
          //                                              //      nodeType: 'text',
          //                                              //      value: ' world!',
          //                                              //      data: {},
          //                                              //      marks: [{ type: 'italic' }]
          //                                              // }
          //                                         ]
          //                                    }
          //                               ]
          //                          } : {
          //                               nodeType: 'document',
          //                               data: {},
          //                               content: []
          //                          }
          //                },
          //                // customBanner: {
          //                //      'en-US': ''
          //                // },
          //                videoUrl: {
          //                     'en-US': eGroup.videoUrl
          //                },
          //                // paymentType: {
          //                //      'en-US': 'Payroll'
          //                // },
          //                // groupPageName: {
          //                //      'en-US': ''
          //                // },
          //                payrollNumber: {
          //                     'en-US': eGroup.payrollNumber
          //                },
          //                groupNumber: {
          //                     'en-US': eGroup.groupNumber
          //                },
          //                affiliateNumber: {
          //                     'en-US': eGroup.affiliateNumber
          //                },
          //                // trackingCode: {
          //                //      'en-US': ''
          //                // },
          //                specificPhone: {
          //                     'en-US': eGroup.specificPhone
          //                },
          //                searchable: {
          //                     'en-US': eGroup.searchable == 'TRUE' ? true : false
          //                },
          //                // groupLogoLegacy: {
          //                //      'en-US': ''
          //                // },
          //                // customBannerLegacy: {
          //                //      'en-US': ''
          //                // },
          //                seoTitleTag: {
          //                     'en-US': eGroup.seoTitleTag
          //                },
          //                alternateSearchTerm: {
          //                     'en-US': eGroup.alternateSearchTerm
          //                },
          //                omniturePageNameLegacy: {
          //                     'en-US': eGroup.omniturePageNameLegacy
          //                },
          //                pageType: {
          //                     'en-US': eGroup.pageType
          //                },
          //                templateType: {
          //                     'en-US': eGroup.templateType
          //                }
          //           }
          //      }))
          //      .then((newEntry) => { console.log(index) })
          //      .catch(console.error)
     })
}

export const importHttpRedirects = (httpRedirects) => {
     httpRedirects.map(({ node: rule }, index) => {
          // client.getSpace(process.env.CONTENTFUL_SPACE_ID)
          //      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
          //      .then((environment) => environment.createEntry('httpRedirects', {
          //           fields: {
          //                contentName: {
          //                     'en-US': rule.Content_Name
          //                },
          //                redirectType: {
          //                     'en-US': rule.Redirect_Type
          //                },
          //                domainName: {
          //                     'en-US': rule.Domain_Name
          //                },
          //                matchType: {
          //                     'en-US': rule.Match_Type
          //                },
          //                oldUrl: {
          //                     'en-US': rule.Old_URL
          //                },
          //                oldUrlCharacterCount: {
          //                     'en-US': parseInt(rule.Old_URL_Character_Count)
          //                },
          //                newUrl: {
          //                     'en-US': rule.New_URL
          //                },
          //                headerResponse: {
          //                     'en-US': rule.Header_Response
          //                },
          //           }
          //      }))
          //      .then((newEntry) => { })
          //      .catch(console.error)
     })
}

export const pulishEntries = (contentType) => {
     console.log('Publishing entries from Content Type: ' + contentType);

     // client.getSpace(process.env.CONTENTFUL_SPACE_ID)
     //      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
     //      .then((environment) => environment.getEntries(
     //           {
     //                'content_type': contentType,
     //                order: 'sys.updatedAt',
     //                limit: 250,
     //                skip: 0
     //           }
     //      )) // you can add more queries as 'key': 'value'
     //      .then((response) => {
     //           response.items.map((item, index) => {
     //                if (!item.isPublished()) {
     //                     item.publish();
     //                }
     //           })
     //      })
     //      .catch(console.error)
}

export const unPulishEntries = (contentType) => {
     console.log('Unpublishing entries from Content Type: ' + contentType);

     // client.getSpace(process.env.CONTENTFUL_SPACE_ID)
     //      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
     //      .then((environment) => environment.getEntries(
     //           {
     //                'content_type': contentType,
     //                limit: 250,
     //                skip: 0
     //           }
     //      )) // you can add more queries as 'key': 'value'
     //      .then((response) => {
     //           response.items.map((item, index) => {
     //                if (item.isPublished()) {
     //                     item.unpublish();
     //                }
     //           })
     //      })
     //      .catch(console.error)
}

export const deleteEntries = (contentType) => {
     console.log('Deleting entries from Content Type: ' + contentType);

     // client.getSpace(process.env.CONTENTFUL_SPACE_ID)
     //      .then((space) => space.getEnvironment(process.env.CONTENTFUL_ENVIRONMENT))
     //      .then((environment) => environment.getEntries({ 'content_type': contentType, limit: 250, skip: 0 })) // you can add more queries as 'key': 'value'
     //      .then((response) => {
     //           response.items.map((item, index) => {
     //                if (!item.isPublished()) {
     //                     item.delete();
     //                }
     //           })
     //      })
     //      .catch(console.error)
}