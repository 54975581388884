import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getIsPreviewStatus } from '../state/app';
import { addSlashToStr, getArticleOrCategorySlugs, getArticleOrCategoryBreadcrumbs } from './utilities';

const getData = graphql`
     query {
          site {
               siteMetadata {
                    siteTitle: title
                    siteDesc: description
                    author
                    siteUrl
               }
          }
     }
`


// const mapBreadcrumb = (arr) => {

// }

const Seo = ({ title, path, canonical, description, robots, image, isPreview, category, article, hasBreadcrumb }) => {
     const { site } = useStaticQuery(getData)

     const { siteDesc, siteTitle, siteUrl } = site.siteMetadata

     let url_string = '';

     if (typeof path != 'undefined') {
          // Can only read environment variable from .env.* file
          // Reference document: https://www.gatsbyjs.org/docs/environment-variables/#server-side-nodejs
          url_string = path ? addSlashToStr(process.env.GATSBY_DOMAIN + path) : process.env.GATSBY_DOMAIN;
     } else {
          url_string = (typeof window !== `undefined`) ? addSlashToStr(process.env.GATSBY_DOMAIN + (window.location.pathname.charAt(0) == '/' ? window.location.pathname.substr(1) : window.location.pathname)) : '';
     }

     const seoTitle = title != null ? title : siteTitle;

     const seoDescription = description ? description : siteDesc; // Long text in graphql is diffent with long text in contentful preview

     const seoRobots = robots ? <meta name="robots" content={robots} /> : "";

     const seoCanonical = canonical ? addSlashToStr(canonical) : url_string;

     const seoImage = image != null ? (isPreview ? image.fields.file.url : image.file.url) : "//images.ctfassets.net/440y9b545yd9/4DSKXKpmQWwNZUvJ8z4PAT/5861e9555a9f576fd80b6a2876fd8b64/nationwide_sq.jpg";

     var arrGlobal = "";
     var breadcrumbId = "";
     if (typeof hasBreadcrumb != 'undefined' && hasBreadcrumb == true) {
          var urlOrigin = (typeof window !== `undefined`) ? window.location.origin : ''
          breadcrumbId = `,"breadcrumb": {"@id": "${url_string}#breadcrumb"}`;
          if (typeof category != "undefined") {
               var breadcrumbSlug = getArticleOrCategoryBreadcrumbs(category);
               var categorySlug = '/healthzone/' + getArticleOrCategorySlugs(category).reverse().join('/');
          }

          arrGlobal = `${arrGlobal}{"@type": "ListItem","position": ${1},"item": {"@type": "WebPage","@id":"${urlOrigin + "/healthzone/"}","url":"${urlOrigin + "/healthzone/"}","name":"Healthzone"}},`
          if (typeof article != "undefined" && typeof category != "undefined") {
               breadcrumbSlug.reverse().map((val, i) => {
                    arrGlobal = `${arrGlobal}{"@type": "ListItem","position": ${i + 2},"item": {"@type": "WebPage","@id":"${urlOrigin + val.slug + '/'}","url":"${urlOrigin + val.slug + '/'}","name":"${val.breadcrumb}"}},`
               })
               arrGlobal = `${arrGlobal}{"@type": "ListItem","position": ${breadcrumbSlug.length + 2},"item": {"@type": "WebPage","@id":"${url_string}","url":"${url_string}","name":"${article.breadcrumbValue}"}}`
          } else {
               breadcrumbSlug.reverse().map((val, i) => {
                    if (val.slug != categorySlug) {
                         arrGlobal = `${arrGlobal}{"@type": "ListItem","position": ${i + 2},"item": {"@type": "WebPage","@id":"${urlOrigin + val.slug + '/'}","url":"${urlOrigin + val.slug + '/'}","name":"${val.breadcrumb}"}},`
                    } else {
                         arrGlobal = `${arrGlobal}{"@type": "ListItem","position": ${breadcrumbSlug.length + 1},"item": {"@type": "WebPage","@id":"${url_string}","url":"${url_string}","name":"${val.breadcrumb}"}}`
                    }
               })
          }

          arrGlobal = `,{"@type": "BreadcrumbList","@id": "${url_string}#breadcrumb","itemListElement": [${arrGlobal}]}`
     }

     return (
          <Helmet htmlAttributes={{ lang: 'en' }} title={`${seoTitle}`} >
               <meta name="description" content={seoDescription} />
               <link rel="canonical" href={seoCanonical} />

               {seoRobots && seoRobots}

               <meta property="og:locale" content="en_US" />
               <meta property="og:type" content="website" />
               <meta property="og:title" content={seoTitle} />
               <meta property="og:url" content={seoCanonical} />
               <meta property="og:description" content={seoDescription} />
               <meta property="og:site_name" content="Nationwide Pet Insurance" />
               <meta property="og:image" content={seoImage} />
               <meta property="og:image:secure_url" content={seoImage} />
               <meta property="fb:app_id" content="" />


               {/* Twitter Card tags */}
               <meta name="twitter:site" content="@NationwidePet" />
               <meta name="twitter:title" content={seoTitle} />
               <meta name="twitter:description" content={seoDescription} />
               <meta name="twitter:image" content={seoImage} />
               <meta name="twitter:card" content="summary_large_image" />
               <meta property="og:image:width" content="1200" />
               <meta property="og:image:height" content="630" />

               <script type="application/ld+json">{`
               {
                    "@context": "http://schema.org",
                    "@graph":[
                         {
                              "@type":"WebSite",
                              "@id":"${url_string}#website",
                              "url":"${url_string}",
                              "name":"Nationwide Pet Insurance",
                              "potentialAction": {
                                   "@type":"SearchAction",
                                   "target":"${url_string}?s={search_term_string}",
                                   "query-input":"required name=search_term_string"
                              }
                         },
                         {
                              "@type":"WebPage",
                              "@id":"${seoCanonical}#webpage",
                              "url":"${seoCanonical}",
                              "inLanguage":"en-US",
                              "name":"${seoTitle}",
                              "isPartOf":{"@id":"${seoCanonical}#website"},
                              "datePublished":"2019-09-06T04:10:11+00:00",
                              "dateModified":"2020-03-09T19:38:28+00:00",
                              "description":"${seoDescription}"
                              ${breadcrumbId}
                         }
                         ${arrGlobal}
                    ]
               }
               `}</script>
          </Helmet>
     )
}

Seo.propTypes = {
     isPreview: PropTypes.bool,
}

const mapStateToProps = (state) => {
     return {
          isPreview: getIsPreviewStatus(state),
     };
}

export default connect(mapStateToProps)(Seo);
