import React from 'react';
import Container from './container';
import { Helmet } from 'react-helmet';
import { withPrefix } from "gatsby";

class Template extends React.Component {
     render() {
          const { children } = this.props

          let rootPath = `/`
          if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
               rootPath = __PATH_PREFIX__ + `/`
          }
          return (
               <Container>
                    <a className="nw-header__skip" href="#main-content">Skip to main content</a>
                    {children}
                    <Helmet>
                         <script src={withPrefix('js/common.js')} type="text/javascript" />
                    </Helmet>
               </Container>
          )
     }
}

export default Template
